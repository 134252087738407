<template>
<!-- start or stop fooocus -->
<div class="dashboard-agent-wrapper">
<!--
  <div class="action-row">
    <p>Fooocus</p> |
    <p @click="startFooocus()" class="action">Start</p> |
    <p @click="stopFooocus()" class="action">Stop</p> |
    <p @click="statusFooocus()" class="action">Status</p>
  </div>
  <div class="action-row">
    <p>Ollama</p> |
    <p @click="startOllama()" class="action">Start</p> |
    <p @click="stopOllama()" class="action">Stop</p> |
    <p @click="statusOllama()" class="action">Status</p>
  </div>
  <div class="action-row">
    <p>Stable Diffusion</p> |
    <p @click="startStableDiffusion()" class="action">Start</p> |
    <p @click="stopStableDiffusion()" class="action">Stop</p> |
    <p @click="statusStableDiffusion()" class="action">Status</p>
  </div>
-->
<div class="action-row">
  <table>
    <tr>
      <td><p>Fooocus</p></td>
      <td><p @click="startFooocus()" class="action">
        <i class="fas fa-play fa-2xs"></i>
      </p></td>
      <td><p @click="stopFooocus()" class="action">
        <i class="fas fa-stop fa-2xs"></i>
      </p></td>
      <td><p @click="statusFooocus()" class="action">
        <i class="fas fa-question fa-2xs"></i>
      </p></td>
    </tr>
    <tr>
      <td><p>Ollama</p></td>
      <td><p @click="startOllama()" class="action">
        <i class="fas fa-play fa-2xs"></i>
      </p></td>
      <td><p @click="stopOllama()" class="action">
        <i class="fas fa-stop fa-2xs"></i>
      </p></td>
      <td><p @click="statusOllama()" class="action">
        <i class="fas fa-question fa-2xs"></i>
      </p></td>
    </tr>
    <tr>
      <td><p>Stable Diffusion</p>
      <td><p @click="startStableDiffusion()" class="action">
        <i class="fas fa-play fa-2xs"></i>
      </p></td>
      <td><p @click="stopStableDiffusion()" class="action">
        <i class="fas fa-stop fa-2xs"></i>
      </p></td>
      <td><p @click="statusStableDiffusion()" class="action">
        <i class="fas fa-question fa-2xs"></i>
      </p></td>
    </tr>
  </table>
</div>

</template>

<script>
import WidgetMixin from '@/mixins/WidgetMixin';

export default {
  mixins: [WidgetMixin],
  data() {
    return {
      lastResponse: null,
    };
  },
  computed: {
    apiKey() {
      return this.parseAsEnvVar(this.options.apiKey);
    },
    endpoint() {
      return 'https://dashboard.arnaudjacques.me/.myapi';
    },
  },
  filters: {
  },
  methods: {
    startFooocus() {
      this.fetchData('Start Fooocus');
    },
    stopFooocus() {
      this.fetchData('Stop Fooocus');
    },
    statusFooocus() {
      this.fetchData('Status Fooocus');
    },
    startOllama() {
      this.fetchData('Start Ollama');
    },
    stopOllama() {
      this.fetchData('Stop Ollama');
    },
    statusOllama() {
      this.fetchData('Status Ollama');
    },
    startStableDiffusion() {
      this.fetchData('Start StableDiffusion');
    },
    stopStableDiffusion() {
      this.fetchData('Stop StableDiffusion');
    },
    statusStableDiffusion() {
      this.fetchData('Status StableDiffusion');
    },
    fetchData(message) {
      this.makeRequest(this.endpoint, { 'X-API-KEY': this.apiKey }, 'POST', message)
        .then((response) => {
          this.processData(response.data);
          this.$toasted.show(response.message, { className: response.success ? 'toast-success' : 'toast-error' });
        })
        .catch((dataFetchError) => {
          this.finishLoading();
          this.$toasted.show(`Unable to fetch data: ${dataFetchError}`, { className: 'toast-error' });
        })
        .finally(() => {
          this.finishLoading();
        });
    },
    processData(data) {
      this.lastResponse = data;
    },
    tooltip(content) {
      return {
        content, html: true, trigger: 'hover focus', delay: 250,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard-agent-wrapper {
  p {
    font-size: 1rem;
    margin: 0.5rem auto;
    color: var(--widget-text-color);
  }
  .action-row {
    width: 100%;
    max-height: 5rem;
    display: inline-table;
    border-radius: var(--curve-factor);
    margin: 1rem auto 0.5rem auto;
    overflow: hidden;
  }
  .action {
    cursor: pointer;
  }
  table {
    width: 100%;
  }
  td i.fas {
    font-size: 1em;
  }
}
</style>
